.banner {

	padding-bottom: 30px;
	text-align: center;

	.banner_visit {
		line-height: 1em;
	}

	@include media-breakpoint-up(md) {
		padding: 30px 0 0;

		.banner_branding { padding: 0; }

		.logo { margin-top: 0; }
		
		.contact-call,
		.contact-address { margin: 0; }
		
		.hours-now-wrap { padding-top: 0; padding-bottom: 0; }

	}

	@include media-breakpoint-up(lg) {
		.logo { margin: 0 auto; }
	}


}