.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99000;
    background-color: black;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.9;
    display: none;
}

.lightbox {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 99500;
    text-align: center;
    line-height: 0;
    font-weight: $body-weight-normal;
    @include media-breakpoint-up(md) {
        margin-top: 75px;
    }
}

.lightbox .lb-image {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    //border-radius: 3px;
}

.lightbox a img { border: none; }

.lb-outerContainer {
    position: relative;
    padding: 3px;
    overflow: hidden;
    background-color: white;
    *zoom: 1;
    margin: 0 auto;
    //border-radius: 3px 3px 0 0;
}

.lb-outerContainer:after {
    content: "";
    display: table;
    clear: both;
}

//.lb-container { padding: 15px; }

.lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0;
}

.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background: url("/_/images/layout/loading.gif") no-repeat;
}

.lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.lb-container > .nav {
    left: 0;
}

.lb-nav a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
    height: 100%;
    cursor: pointer;
    display: block;
}

.lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    background: url("/_/images/icons/lb_prev.png") left 48% no-repeat;
    background-size: 28px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    @include transition(opacity 0.6s);
}

.lb-nav a.lb-prev:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    background: url("/_/images/icons/lb_next.png") right 48% no-repeat;
    background-size: 28px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    @include transition(opacity 0.6s);
}

.lb-nav a.lb-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-dataContainer {
    margin: 0 auto;
    padding: 7px 0;
    *zoom: 1;
    width: 100%;
    border-top: 3px solid $white;
    background-color: transparent;
    color: $white;
}

.lb-dataContainer:after {
    content: "";
    display: table;
    clear: both;
}

.lb-data {
    padding: 0 4px;
    //color: #ccc;
}

.lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
}

.lb-data .lb-caption {
    display: inline-block;
    padding: 6px 5px 0;
    font-size: 1rem;
    font-weight: $body-weight-bold;
    text-transform: uppercase;
    line-height: 1em;
}

.lb-data .lb-number {
    display: none !important;
    visibility: hidden !important;
}

.lb-data .lb-close {
    display: block;
    float: right;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    background: url("/_/images/icons/close.png") center center no-repeat;
    background-size: 20px 20px;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    @include transition(opacity 0.2s);
}

.lb-data .lb-close:hover {
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}
