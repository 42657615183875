@mixin limbo {
	li { margin-bottom: 0 !important; }
}

%list, .list {
	margin: 0 0 1em;
	padding: 0 0 0 1.2em;	
	li:not(:last-child) { margin-bottom: 0.25em; }
}

		@mixin list {
			margin: 0 0 1em;
			padding: 0 0 0 1.2em;	
			li:not(:last-child) { margin-bottom: 0.25em; }		
		}

%list_unstyled, .list-unstyled {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}
		
		@mixin list_unstyled {
			margin-left: 0;
			padding-left: 0;
			list-style-type: none;
			list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);		
		}

%list_inline, .list-inline {
	@extend %list_unstyled;
	li { display: inline-block; }
}

		@mixin list_inline {
			@include list_unstyled;
			li { display: inline-block; }
		}

.list_icon {

	@extend %list_unstyled;

	li {
		padding-left: 22px;
		background-repeat: no-repeat;
		background-size: 15px;
		background-position: left center;
	}

	&.list-inline {
		li:not(:last-child) {
			margin-right: 15px;
		}
	}

}

	.icon_check-primary li { background-image: url("/_/images/icons/cm_primary.png"); }
	.icon_check-secondary li { background-image: url("/_/images/icons/cm_secondary.png"); }
	.icon_check-success li { background-image: url("/_/images/icons/cm_success.png"); }



[class*="list-block"] {
	@include list;
	@include list_inline;

	li {
		@include transform(skew(-15deg));
		margin-bottom: 0.5em !important;
		padding: 3px 10px;
		font-weight: $body-weight-bold;
		letter-spacing: 0.025em;
		text-shadow: 0 1px 1px rgba(0,0,0,0.4);
		background-color: theme(text, base);
		color: $white;
	}

	li:not(:last-child) {
		margin-right: 0.25em;
	}

}

	.list-block--primary li { background-color: theme(primary, desat); }
	.list-block--secondary li { background-color: theme(secondary, base); }
	.list-block--highlight li { background-color: theme(highlight, base); }
	.list-block--accent li { background-color: theme(accent, base); }
	.list-block--success li { background-color: theme(success, base); }
	.list-block--danger li { background-color: theme(danger, base); }
	.list-block--warning li { background-color: theme(warning, base); }
	.list-block--info li { background-color: theme(info, base); }


.list-double-sm-up {

	@include media-breakpoint-up(sm) {
		@include make-flex;
		> li {
			width: percentage(1/2);
		}
	}

}

.list-triple-md-up {

	@include media-breakpoint-up(md) {
		@include make-flex;
		> li {
			width: percentage(1/3);
		}
	}	

}
