.review {

	padding: 0 60px;

	cite {
		display: block;
		margin-top: 1em;
		@include font-size(15);
		font-style: normal;
		line-height: 1em;
		text-align: center;
		img {
			display: block;
			width: 120px;
			margin: 0 auto 5px;
		}
	}

	.reivew-wrap {
		@include make-flex;
		align-items: center;
		justify-content: center;
	}

	.widget_review & { padding: 0; }

	.reviews_main_wrapper & {
		margin-bottom: 20px;
		padding: 30px;
		text-align: left;
		border: 1px solid #bbb;
		border-left: 10px solid $orange;
		background-color: rgba($white,0.5);
		p { margin: 0; }
		cite {
			text-align: left;
			line-height: 1.325em;
			img { margin: 0 0 5px; }
		}
	}

}