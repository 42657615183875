.slider_reviews {

	.slick-prev,
	.slick-next {
		background-color: transparent;
	}

	.slick-prev { background-image: url("/_/images/icons/slide-review-prev.png") }
	.slick-next { background-image: url("/_/images/icons/slide-review-next.png") }	



	@include media-breakpoint-up(lg) {}

}