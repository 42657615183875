.site_info {
	position: relative;
	padding: 30px 0;
	text-align: center;
	border-top: 6px solid theme(secondary, base);
	background-color: theme(primary, dark);
	color: $white;
}

.info_copyright {

	@include font-size(14);
	line-height: 1.3em;
	text-align: center; 

	a {
		font-weight: $body-weight-bold;
		line-height: 1em;
		text-decoration: none;
		color: $white;
	}

	.copyright-list { @include list_unstyled; }

}