.block_welcome {

	padding: 15px 15px 45px;
	text-align: center;

	@include media-breakpoint-up(lg) {
		text-align: left;
		.row { align-items: flex-start !important; }
		.welcome_content { @include make-col(7); }
		.welcome_media { @include make-col(5); }
	}
	@include media-breakpoint-up(xl) {
		.welcome_content { @include make-col(6); }
		.welcome_media { @include make-col(4); }
	}

}

.welcome-title {
	&:before { content: "Welcome to "; }
}

/* .welcome_media {

	text-align: center;

	h3 { margin-top: 15px; }

	.welcome_media_wrapper {
		max-width: 360px;
		margin: 0 auto;
	}

	.slider_showroom {
		padding: 5px;
		line-height: 0px;
		border: 1px solid #ccc;
		background-color: $white;
	}

	@include media-breakpoint-up(lg) {

		.welcome_media_wrapper {
			position: relative;
			h3 {
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				width: auto;
				margin: 5px;
				padding: 11px 10px 5px;
				z-index: 900;
				@include font-size(22);
				background-color: rgba($white, 0.85);
				//color: theme(primary, dark);
			}
		}

	}

} */



	.gmb-best {
		display: block;
		max-width: 195px;
		margin: 0 auto;
		padding: 5px;
		@include font-size(14);
		font-weight: $body-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.0325em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		color: theme(text, base) !important;
		img {
			max-width: 100px;
			margin: 0 auto;
		}

		@include media-breakpoint-up(md) {
			padding: 0;
			max-width: none;
			img {
				display: inline-block;
				vertical-align: middle;
			}
		}

		@include media-breakpoint-up(lg) {
			margin: 0;
			text-align: left;
			img { margin: 0; }
		}

	}


	.welcome_wrapper {
		padding: 20px;
		border-radius: 6px;
		border: 2px solid #71c6d0;
		background-color: #fff;

		@include media-breakpoint-down(md) {
			margin-top: 15px;
		}

		h2 {
			margin: 0 0 15px;
		}

		p {
			margin-bottom: 0;
		}

	}