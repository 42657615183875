.media_objects_wrapper {}


.media_object {

	.media_image img {
		display: block;
		width: 100%;
	}

	@include media-breakpoint-up(md) {
		@include make-flex;
		align-items: center;
		.media_image,
		.media_content {
			position: relative;
			box-sizing: border-box;
			min-height: 1px;
		}
		.media_image { width: 45%; }
		.media_content { width: 55%; }
	}

	@include media-breakpoint-up(lg) {
		.media_image { width: 45%; }
		.media_content { width: 55%; }
	}

}

	.media_image {
		margin-bottom: 15px;
	}

	.media_content {
		> *:last-child { margin-bottom: 0; }
	}

		.media-title {
			margin-top: 0;
		}




.services_wrapper {

	margin-top: 45px;

	.service_object {

		padding: 0 0 30px;

		&:not(:last-child) {
			margin: 0 0 2em;
			border-bottom: 1px solid #ccc;
			box-shadow: 0 1px 0 0 #fff;
		}

		&:nth-child(even) .service_image img {
			box-shadow: 0 0 0 3px theme(secondary, base);
		}

		@include media-breakpoint-up(md) {
			align-items: flex-start;
			&:nth-child(even) {
				.media_image { padding-right: 30px; }
			}
			&:nth-child(odd) {
				.media_image {
					order: 2;
					padding-left: 30px;
					img { margin: 0 0 0 auto; }
				}
				.media_content { order: 1; }
			}
		}

	}	

	.service_image img {
		max-width: 270px;
		padding: 0;
		border-radius: 100%;
		border: 5px solid $white;
		box-shadow: 0 0 0 3px theme(primary, base);
	}

	.service_content {
		
		line-height: 1.4em;

		.service-title {
			@include font-size(22);
		}

		.list { line-height: 1.2em; }

	}

}


.brand_object {
	align-items: center;
	margin: 0 0 2em;
	padding: 0 0 2em;
	border-bottom: 1px solid #ccc;
	box-shadow: 0 1px 0 0 #fff;
}