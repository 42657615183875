.widget_review {

	@include font-size(15);
	text-shadow: none;
	line-height: 1.425em;

	background-color: $white;
	border: 2px solid $orange;
	color: theme(text, base);


	.review {
		padding: 0 15px;
		p { margin-bottom: 0; }
		cite img {
			margin-bottom: 10px;
		}
	}

}