.block_service {

	padding: 15px;
	background-color: theme(primary, base);
	border-top: 5px solid theme(primary, base);
	border-bottom: 5px solid theme(primary, base);
	background: 	linear-gradient( -45deg, transparent 15%, rgba($white, 0.1) 50%, transparent 85% ),
					linear-gradient( -150deg, rgba(0,0,0,0.3) 20%, transparent 45%, transparent 55%, rgba(0,0,0,0.3) 80% ),
					url("/_/images/layout/page-title.png"),
					theme(primary, base);
	color: $white;

	.row {
		align-items: flex-start !important;
		justify-content: center;
	}

}

	.service_item {
		@include make-col(6);
		padding: 0;
		@include media-breakpoint-up(md) {
			@include make-col(4);
			padding: 0;
		}
		@include media-breakpoint-up(lg) {
			flex: 0 0 20%;
			max-width: 20%;
		}
		@include media-breakpoint-up(lg) {
			flex: 0 0 18%;
			max-width: 18%;
		}
	}

	.service_wrapper {
		display: block;
		padding: 10px; 
		text-align: center;
		> *:last-child { margin-bottom: 0; }

		p {
			@include font-size(14);
			line-height: 1.375em;
		}

		.svgColor { fill: theme(secondary, base); }

	}

		.service-sub-title {
			margin: 10px 0 5px;
			@include font-size(20);
		}


