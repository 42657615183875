.slider_jumbo {
    
    max-width: 1220px;
    margin: 0 auto;

    .slick-prev,
    .slick-next {
        visibility: hidden;
    }

    .slick-prev {
        background-image: url("/_/images/icons/slide-text-prev.png");
    }
    
    .slick-next {
        background-image: url("/_/images/icons/slide-text-next.png");
    }

    .slide_media {
        background-position: center center;
        background-size: cover;            
    }

        .slide-placeholder {
            width: 100%;
        }

    .slide_caption {
        padding: 12px 10px 10px;
        text-align: center;
        border-top: 1px solid $white;
        background-color: theme(primary, base);
        .caption-title {
            margin: 0;
            @include font-size(20);
            letter-spacing: -0.0375em;
        }

    }

    @include media-breakpoint-up(sm) {
        .slide_caption .caption-title {
            @include font-size(24);
        }
    }

    @include media-breakpoint-up(md) {

        .slide { padding: 0 40px; }

        .slick-prev,
        .slick-next {
            visibility: visible;
        }

        .slide_01 .slide_media { background-image: url("/_/images/slides/jumbo/lg/01.jpg") }
        .slide_02 .slide_media { background-image: url("/_/images/slides/jumbo/lg/02.jpg") }
        .slide_03 .slide_media { background-image: url("/_/images/slides/jumbo/lg/03.jpg") }
        .slide_04 .slide_media { background-image: url("/_/images/slides/jumbo/lg/04.jpg") }
        .slide_05 .slide_media { background-image: url("/_/images/slides/jumbo/lg/05.jpg") }
        .slide_06 .slide_media { background-image: url("/_/images/slides/jumbo/lg/06.jpg") }
        .slide_07 .slide_media { background-image: url("/_/images/slides/jumbo/lg/07.jpg") }
        .slide_08 .slide_media { background-image: url("/_/images/slides/jumbo/lg/08.jpg") }
        .slide_09 .slide_media { background-image: url("/_/images/slides/jumbo/lg/09.jpg") }
        .slide_10 .slide_media { background-image: url("/_/images/slides/jumbo/lg/10.jpg") }
        .slide_11 .slide_media { background-image: url("/_/images/slides/jumbo/lg/11.jpg") }
        .slide_12 .slide_media { background-image: url("/_/images/slides/jumbo/lg/12.jpg") }

        .slide-placeholder { visibility: hidden; }

        .slide_caption {
            padding: 12px 15px 10px;
            text-align: left;
            .caption-title { @include font-size(20); }
            .btn { float: right; margin: 0; }
        }

    }

    @include media-breakpoint-up(lg) {

        .slide_caption {
            margin-top: -58px;
            padding: 16px 15px 10px;
            background-color: rgba($color-primary,0.9);
            .caption-title { @include font-size(24); }
            .btn { @include font-size(18); }
        }

    }

}