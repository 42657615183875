.banner_menu {
	padding: 30px 0;
	background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1' width='1' preserveAspectRatio='none'%3E%3Crect fill='%23bbbbbb' width='1' height='1'/%3E%3C/svg%3E") center center repeat-x;
	background-size: 1px 1px;
	line-height: 1em;
	text-align: center;

	.menu {

		display: inline-block;
		width: auto;
		margin: 0 auto;
		padding: 0 30px;
		//background-color: $body-bg;
		background: $white url("/_/images/layout/body.png");
		
		> .menu_item {

			display: inline-block;
			padding: 0 4px;

			&.active {
				> .menu_link {
					@include transform(none);
					font-weight: $body-weight-bolder;
					text-shadow: none;
					border: none;
					background: transparent;
					color: theme(secondary, dark);
				}
			}

			&.open {
				> .menu_link {
					border-color: theme(secondary, dark);
					background-color: theme(secondary, base);
				}
			}

			> .menu_link {
				border-radius: 3px;
				border: 1px solid theme(primary, dark);
				background: theme(primary, base) url("/_/images/icons/hover-secondary.svg") 15% center no-repeat;
				&:hover { border-color: theme(secondary, dark); }
			}

		}

	}

	.menu_link {
		@include transition(all ease-in-out 300ms);
		display: block;
		padding: 10px 15px;
		@include font-size(14);
		font-weight: $body-weight-bold;
		letter-spacing: -0.02em;
		text-shadow: 0 1px 1px rgba(0,0,0,0.3);
		background-size: 0px !important;
		color: $white;
		&:hover {
			@include transition(all ease-in-out 500ms);
			background-size: 200% !important;
			background-position: center center !important;
		}	
		.caret { background-image: url("/_/images/icons/caret.png"); }	
	}

	.dropdown_menu {
		min-width: 221px;
		margin-top: 5px;
		padding: 10px;
		border-radius: 3px;
		border: 1px solid theme(secondary, dark);
		box-shadow: 0 15px 10px -15px rgba(black, 0.6);
		background-color: theme(secondary, base);

		.menu_item:not(:last-child) { border-bottom: 1px solid rgba(black,0.15); }
		.menu_item:not(:first-child) { border-top: 1px solid rgba($white,0.4); }		

		.menu_item.active {
			.menu_link {
				@include font-size(13);
				font-weight: $body-weight-bolder;
				text-shadow: none;
				border: none;
				background: $white;
				color: theme(secondary, dark);
				&:before { content: none; }
			}
		}

		.menu_link {

			padding-left: 5px;
			overflow: hidden;
			font-weight: $body-weight-bold;
			text-transform: uppercase;

			&:before {
				@include transition(all ease-in-out 300ms);
				display: inline-block;
				height: 1em;
				width: 1em;
				margin-left: -1em;
				margin-right: 5px;
				vertical-align: top;
				background-image: url("/_/images/icons/caret.png");
				background-size: contain;
				@include transform(rotate(-90deg) scale(0));
				content: "";
			}

			&:hover { 
				&:before {
					@include transition(all ease-in-out 300ms);
					@include transform(rotate(-90deg) scale(0.9));
					margin-left: 0;
				}
			}

		}

	}

}
