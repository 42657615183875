.block {
	padding: 45px 15px;
	&:not(.content, .block_content, .page_title) .row {
		align-items: center;
		justify-content: center;
	}
}

.block_title {
	margin-bottom: 30px;
	text-align: center;
	background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1' width='1' preserveAspectRatio='none'%3E%3Crect fill='%23bbbbbb' width='1' height='1'/%3E%3C/svg%3E") center center repeat-x;
	background-size: 1px;
	line-height: 1em;
}

	.block-title {
		@extend %h2;
		display: inline-block;
		margin: 0;
		padding: 0 15px;
		background: $white url("/_/images/layout/body.png");
	}

.block_content {
	> *:last-child { margin-bottom: 0; }
}

.block_more {
	margin-top: 15px;
}
