$abg:		accent;

.announce {
	padding: 15px;
	
	@include font-size(15);
	font-weight: $body-weight-bolder;
	line-height: 1em;
	text-shadow: 0 1px 1px rgba(black, 0.4);
	//text-transform: uppercase;

	border-top: 3px solid $body-bg;
	border-bottom: 3px solid $body-bg;
	background-image: linear-gradient(
		to right,
		darken(theme($abg, dark), 10%),
		theme($abg, dark) 40%,
		theme($abg, dark) 60%,
		darken(theme($abg, dark), 10%)
	);
	color: $white;

	@include media-breakpoint-up(md) {
		@include font-size(17);
	}

}