.widget {

	@include card(30px 15px, 3px, $white);

	max-width: 420px;
	margin-left: auto;
	margin-right: auto;

	text-shadow: 0 1px 1px rgba(0,0,0,.3);
	box-shadow: none;
	color: $white;

	&.widget--primary { background-color: theme(primary, base); }
	&.widget--secondary { background-color: theme(secondary, base); }
	&.widget--highlight { background-color: theme(highlight, base); }
	&.widget--accent { background-color: theme(accent, base); }
	&.widget--success { background-color: theme(success, base); }
	&.widget--danger { background-color: theme(danger, base); }
	&.widget--warning { background-color: theme(warning, base); }
	&.widget--info { background-color: theme(info, base); }
	&.widget--text { background-color: theme(text, base); }
	&.widget--review { background-color: theme(review, base); }
	&.widget--orange { background-color: $orange; }

	&.widget--plain {
		padding: 0;
		background-color: transparent;
		color: theme(text, base);
		text-shadow: none;
	}

}

	.widget-title {
		margin: 0 0 0.5em;
		font-size: 1.2rem;
		font-family: $font-family-sans-serif;
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0425em;
		line-height: 1em;
	}
