.coverage {

	padding: 30px;

	.coverage_title {
		margin: 0 0 15px;
		@include font-size(18);
		font-family: $font-family-sans-serif;
		font-weight: $body-weight-bolder;
		text-transform: uppercase;
		letter-spacing: -0.0325em;
		color: theme(primary, base);
		a { text-decoration: none; }
	}

	p { 
		margin-bottom: 15px;
		line-height: 1.325em;
		&:last-of-type { margin-bottom: 0; }
	}

		.coverage_county {
			@include font-size(14);
			font-weight: $body-weight-bold;
		}

		.coverage_city {
			@include font-size(14);
		}

}

.block.coverage {
	text-align: center;
	box-shadow: none;
	border-top: 1px solid darken($body-bg, 7.5%);
	background-color: $white;

	.row { justify-content: center; }

 	@include media-breakpoint-up(lg) { .wrap { @include make-col(10); } }
	@include media-breakpoint-up(xl) { .wrap { @include make-col(9); } } 


}