.block_reviews {

	@include media-breakpoint-up(lg) {
		.row { align-items: center; }
		.reviews_content { @include make-col(10); }
		.reviews_more { @include make-col(4); margin-top: 0; }
	}
	@include media-breakpoint-up(xl) {
		.reviews_content { @include make-col(8); }
	}

}

	.reviews_content .review {
		text-align: center;
		.review-wrap p {
			margin-bottom: 0;
		}
	}