.page_title {

	padding: 0;
	text-shadow: 0 1px 2px #888;
	border-top: 5px solid $white;
	border-bottom: 5px solid $white;
	background: 	linear-gradient( -45deg, transparent 15%, rgba($white, 0.1) 50%, transparent 85% ),
					linear-gradient( -150deg, rgba(0,0,0,0.3) 20%, transparent 45%, transparent 55%, rgba(0,0,0,0.3) 80% ),
					url("/_/images/layout/page-title.png"),
					theme(primary, base);
	color: $white;

	.image_wrap { padding: 20px 0 15px; }

	.title_wrap {
		padding: 10px 0;
	}

	.page-title {
		line-height: 1em;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
	}

	@include media-breakpoint-up(md) {
		.image_wrap { padding-top: 45px; }
	}
	
	@include media-breakpoint-up(lg) {
		.image_wrap { padding-top: 90px; }
	}

}

.pt_img {

	.page_title {
		.container {
			max-width: 1170px;
			padding: 0;
		}
		.row { margin: 0; }
	}

	.title_wrap {
		margin: 0 auto;
		padding: 10px 15px;
	}

	.page-title {
		margin: 0 auto;
		padding: 0 10px;		
	}

	@include media-breakpoint-up(sm) {
		.page-title { max-width: 540px; }
	}

	@include media-breakpoint-up(md) {
		.page_title {
			background-size: cover;
			background-position: center center;
		}

		.image_wrap {
			padding: 240px 0 0;
			background-size: cover;
			background-position: center center;			
		}

		.title_wrap {
			padding: 15px 15px 5px;
			background-color: rgba(theme(secondary, dark), 0.7);
		}

		.page-title { max-width: 720px; }

		&.page_services {
			.page_title {
				background-image: url("/_/images/page-title/services-bg.jpg");
				.image_wrap {
					background-image: url("/_/images/page-title/services.jpg");
				}
			}
		}

			&.service_window_treatments {
				.page_title {
					background-image: url("/_/images/page-title/window-treatments-bg.jpg");
					.image_wrap {
						background-image: url("/_/images/page-title/window-treatments.jpg");
					}
				}
			}

		&.page_gallery {
			.page_title {
				background-image: url("/_/images/page-title/gallery-bg.jpg");
				.image_wrap {
					background-image: url("/_/images/page-title/gallery.jpg");
				}
			}
		}

		&.page_about {
			.page_title {
				background-image: url("/_/images/page-title/about-bg.jpg");
				.image_wrap {
					background-image: url("/_/images/page-title/about.jpg");
				}
			}
		}

		&.page_contact {
			.page_title {
				background-image: url("/_/images/page-title/contact-bg.jpg");
				.image_wrap {
					background-image: url("/_/images/page-title/contact.jpg");
				}
			}
		}

	}
	
	@include media-breakpoint-up(lg) {
		.image_wrap { padding-top: 380px; }
		.page-title { max-width: 960px; }
	}

	@include media-breakpoint-up(xl) {
		.page-title { max-width: 1140px; }
	}

}
