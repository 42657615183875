.logo {
	display: block;
	margin: -41px auto 10px;
	max-width: 240px;
	line-height: 1em;

	@include media-breakpoint-up(md) {
		max-width: 360px;
	}

}

	.logo-footer {
		display: block;
		max-width: 200px;
		margin: 0 auto;
	}